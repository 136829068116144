<template>
  <!-- 宠物粮食添加页面 -->
  <moe-page title="宠物粮食添加">
    <moe-form ref="cerealForm" :model="cereal" :rules="cerealRules">
      <el-form-item label="宠物粮食品牌" prop="brand">
        <el-input
          v-model.trim="cereal.brand"
          placeholder="请输入宠物粮食品牌"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="宠物类型" prop="pesType">
        <moe-select
          v-model="cereal.pesType"
          type="petType"
          placeholder="选择宠物类型"
        />
      </el-form-item>

      <el-form-item label="宠物粮食名称" prop="names">
        <div class="cereal-list">
          <div
            class="cereal-item"
            v-for="(item, index) of cereal.names"
            :key="index"
          >
            <el-input
              v-model.trim="cereal.names[index].name"
              placeholder="请输入宠物粮食品牌"
              maxlength="50"
              clearable
            />
            <el-button
              type="text"
              icon="el-icon-circle-plus-outline"
              @click="cerealNameAdd"
              v-show="cereal.names.length - 1 === index"
            />
            <el-button
              type="text"
              icon="el-icon-delete"
              v-show="cereal.names.length - 1 !== index"
              @click="cerealNameDelete(index)"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item label="品牌主图" prop="img">
        <moe-upload-file
          v-model="cereal.img"
          ossKey="PET_FOOD_BRAND"
          upload-text="上传主图"
          @change="$refs.cerealForm.validateField('img')"
        />
      </el-form-item>
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="cerealLoad"
          @click="cerealInfoSubmit"
        >
          {{ cerealLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'PetCerealAdd',
  data() {
    //宠物粮食信息
    const cereal = {
      brand: '', //品牌
      pesType: '', //宠物类型
      names: [{ name: '' }], //品牌名称
      img: '', //品牌主图
    };

    //宠物粮食数据校验
    const verify = this.$moe_verify.verifyForm;
    const cerealRules = {
      brand: verify.isExtent(['请输入品牌', '长度不能超过 99'], '1~99'),
      pesType: verify.isEmpty('请选择宠物类型'),
      names: {
        required: true,
        validator: (rule, value, callback) => {
          let bool = true;
          value.forEach((k) => {
            if (k.name == '') {
              bool = false;
              callback('请输入品牌名称');
              return;
            }
          });

          if (bool) {
            callback();
          }
        },
      },
      img: verify.isEmpty('请上传品牌主图'),
    };

    return {
      cereal, //宠物粮食信息
      cerealRules, //宠物粮食数据校验
      cerealLoad: false, //提交状态
    };
  },
  methods: {
    /**
     * 粮食名称添加
     **/
    cerealNameAdd() {
      this.cereal.names.push({
        name: '',
      });
    },

    /**
     * 粮食名称删除
     **/
    cerealNameDelete(index) {
      this.cereal.names.splice(index, 1);
    },

    /**
     * 提交宠物粮食信息
     **/
    cerealInfoSubmit() {
      // 表单校验
      this.$refs['cerealForm'].validate(() => {
        this.cerealLoad = true;
        this.$moe_api.PET_API.petCerealAdd(this.cereal)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('添加成功');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          .finally(() => {
            this.cerealLoad = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cereal-item {
  display: flex;
  margin-bottom: 15px;

  .el-button {
    margin-left: 10px;
  }
}
</style>
